<template>
  <div>
    <v-card>
      <v-card-title>Listado de acopios</v-card-title>
      <v-card-text>
        <botonera 
          :botones="botones" 
          alinear="right"
          @crear="dialog = !dialog"
          @cargar="upload = !upload"
          @eliminar="deleteAll"
        ></botonera>
      </v-card-text>
    </v-card>

    <datatable 
      :cabeceras="acopiosListHeaders" 
      :items="acopiosListBody" 
      titulo="Listado de Acopios" 
      label="Escriba Para buscar Acopios" 
      icono="list" 
      color_icono="azul" 
      :tachar="true"
      :acciones="['editar','eliminar']"
      @editar="editAcopio($event)"
      @eliminar="deleteAcopio($event)">
    </datatable>

    <!-- Modal Nuevo Acopio -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <form>
            <v-row class="mx-2">
              <v-col
                class="align-center justify-space-between"
                cols="4"
              >
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Nombre"
                  v-model="acopio.nombre"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-card-actions>
              <v-btn
                @click="closeModal"
              >Cerrar</v-btn>
              <v-btn
                color="primary"
                @click="saveAcopio"
              >Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de acopios desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadAcopios"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'
  import DataTable from '@/components/base/DataTable.vue';

  export default {
    data: () => ({
      title: 'Nuevo Acopio',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      search: null,
      file: null,
      dialog: false,
      upload: false,
      changeState: false,
      empresas: [],
      colors: [],
      sizes: [],
      state: null,
      states: [],
      acopioIndex: -1,
      acopio: {
        id: 0,
        nombre: '',
        empresa: null,
      },
      acopiosListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Nombre',
          align: 'center',
          sortable: true,
          value: 'nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      acopiosListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nuevo Acopio'},
        {boton:'cargar',tooltip:'Carga Masiva'},
        {boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadEmpresas();
      this.loadAcopios();
    },
    methods:{
      async loadEmpresas(){
        let url = `${this.base_url}empresas`;
        await this.axios.get(url, this.headers).then((response)=>{
            this.empresas = response.data;
            this.acopio.empresa = this.empresas[0];
        }).catch((error)=>{
            console.log(error);
        });
      },
      async loadAcopios(){
        this.mostrarLoading('Cargando listado de acopios...')
        let url = `${this.base_url}acopios`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.acopiosListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
            console.log(error);
            this.ocultarLoading()
        });
      },
      async saveAcopio(){
        // validaciones
        this.errors = [];
        if(!this.acopio.nombre){
          this.errors.push("Debe indicar nombre del acopio");
        }else if(this.acopio.nombre.length > 30){
          this.errors.push("Tamaño máximo 30 caracteres");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}acopios`;
        let acopioData = {
          'empresas_id': 0,
          'nombre': this.acopio.nombre,
        };
        if(this.acopio.id > 0){
          url = url + "/" + this.acopio.id;
          await this.axios.put(url, acopioData, this.headers).then((response)=>{
            let index = this.acopiosListBody.map(acopio => {
              return acopio.id;
            }).indexOf(this.acopio.id);
            console.log(response.data);
            this.acopiosListBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.validation_message = error;
              this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, acopioData, this.headers).then((response)=>{
              this.acopiosListBody.push(response.data);
              this.showSnackBar(false);
          }).catch((error)=>{
              this.errors = error.response.data;
              this.validation_message = this.errors.join();
              this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editAcopio(item) {
        console.log('acopio editar',item);

        this.acopioIndex = this.acopiosListBody.indexOf(item);
        this.acopio = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Acopio";
      },
      async deleteAcopio(item) {
        this.$confirm('Desea borrar este acopio?').then(res => {
          if(res){
            let url = `${this.base_url}acopios/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              this.loadAcopios();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nuevo Acopio";
      },
      cleanForm(){
        this.acopio.id = 0;
        this.acopio.nombre = "";
        this.acopio.empresa = null;
        this.dialog = false;
      },
      async uploadAcopios(){
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}uploads/acopios`;
        let formData = new FormData();
        formData.append("excel", this.file);
        let response = null; 
        try{
          response = await this.axios.post(url, formData, this.headers);
          this.file = null;
          this.upload = false;
          this.loadAcopios();
          this.showSnackBar(false);
        }catch(error){
          response = error.response;
          let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
          this.showSnackBar(true);
        }
      },
      async deleteAll(){
        this.$confirm('Desea borrar todos los datos?').then(res => {
          if(res){
            let url = `${this.base_url}truncar/acopios`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.loadAcopios();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async ocultar(item) {
        this.$confirm('Desea ocultar este acopio?').then(res => {
          if(res){
            let url = `${this.base_url}acopios/ocultar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadAcopios();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async mostrar(item) {
        this.$confirm('Desea mostrar este acopio?').then(res => {
          if(res){
            let url = `${this.base_url}acopios/mostrar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadAcopios();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      
    },
    components:{
      'datatable': DataTable
    }
  }
</script>